// src/services/Jobs.service.js

import axios from 'axios';
import Users from '@/services/Users.service.js';
import {uuid} from 'vue-uuid';

export default {
  // creates a workorder with the same jobsite/client info as
  // an existing workorder.
  duplicateWorkOrder(workorder) {
    var newWorkOrder = this.blankWorkOrder();

    newWorkOrder.tenant_uuid = workorder.tenant_uuid;
    newWorkOrder.client_uuid = workorder.client_uuid;
    newWorkOrder.estimate_uuid = workorder.estimate_uuid;
    newWorkOrder.job_site_uuid = workorder.job_site_uuid;
    newWorkOrder.job_uuid = workorder.job_uuid;
    newWorkOrder.status = 'Not Scheduled';

    return newWorkOrder;
  },
  blankWorkOrder() {
    var workorder = {
      uuid: uuid.v4(),
      tenant_uuid: null,
      client_uuid: null,
      estimate_uuid: null,
      job_uuid: null,
      name: '',
      status: '',
      scheduled_date: '',
      event_uuids: [],
      notes: '',
      events: [],
      user: {},
    };
    return workorder;
  },
  blankWorkTask(
    tenant_uuid,
    client_uuid,
    job_uuid,
    estimate_uuid,
    tree_uuid,
    workorder_uuid,
    dbh,
    height
  ) {
    // make sure to set uuids to null if its an empty string... otherwise
    // api will get upset
    return {
      uuid: uuid.v4(),
      tenant_uuid: tenant_uuid,
      client_uuid: client_uuid,
      job_uuid: job_uuid,
      estimate_uuid: estimate_uuid,
      tree_uuid: tree_uuid == '' ? null : tree_uuid,
      workorder_uuid: workorder_uuid == '' ? null : workorder_uuid,
      pricing_method: '',
      status: 'not_complete',
      workcategory: '',
      worktype: '',
      section: '',
      notes: '',
      units: '',
      cost: '',
      dbh: dbh,
      height: height,
      discount: '',
      subtotal: '',
    };
  },
  blankEstimateSection() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: null,
      client_uuid: null,
      estimate_uuid: null,
      name: '',
      approved: true,
    };
  },
  blankServiceRequest() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: '',
      client_uuid: '',
      // job_site_uuid: undefined,
      // number: 0,
      client_name: '',
      requestor_name: '',
      requestor_phone: '',
      requestor_email: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      special_instructions: '',
      service_request_status: 'Submitted',
      date_submitted: new Date().toISOString().substr(0, 10),
      quickbooks_enabled: false,
    };
  },

  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                      GET BY UUID
  // *****************************************************/
  // [ JOBS ]
  async getJob(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/jobs/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ ESTIMATES ]
  async getEstimate(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/estimates/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ ESTIMATES ] NOTE: UNSECURED! ONLY USE FOR
  // PUBLIC ESTIMATE APPROVAL LINK
  async getEstimateforApproval(uuid) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/estimates/public_link/' + uuid,
    });
    return res.data;
  },

  async getServiceRequest(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/service_requests/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getWorkOrder(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/work_orders/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getWorkTaskCountByTree(estimate_uuid, trees) {
    if (!trees) {
      console.log('no trees passed in: ', trees);
      return;
    }

    var response = await axios({
      method: 'GET',
      url: '/service_jobs/work_tasks/getWorkTaskCountByTree',
      params: {
        estimate_uuid: estimate_uuid,
      },
    });

    if (response && response.data) {
      for (let tree of trees) {
        tree.workTaskCount = 0;

        for (let workTaskCount of response.data) {
          if (workTaskCount.tree_uuid == tree.uuid) {
            tree.workTaskCount = parseInt(workTaskCount.count);
            break;
          }
        }
      }
    }
    return trees;
  },

  // [ SCHEDULING - EVENTS ] FIND BY LIST
  async loadWorkOrderEventsByUUID(event_uuids, accessToken) {
    if (event_uuids && event_uuids.length > 0) {
      let res = await axios({
        method: 'GET',
        url: '/service_scheduling/events/all',
        params: {
          event_uuids: event_uuids,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (res) {
        //sort array by the soonest scheduled date
        res.data.sort(function (a, b) {
          return new Date(a.start_time) - new Date(b.start_time);
        });

        return res.data;
      }
    } else {
      return [];
    }
  },

  async getServiceRequestEstimate(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/service_requests/estimate/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.data;
  },

  // [ ESTIMATES ]   FIND BY JOB UUID
  async getEstimateByJobUUID(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/estimates/byjobuuid/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ SERVICE REQUEST ]   FIND BY JOB UUID
  async getServiceRequestByJobUUID(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/service_requests/byjobuuid/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                      UPDATE
  // *****************************************************/

  async updateJob(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/jobs/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ ESTIMATES]
  // NOTE: UNSECURED!! Needed for estimate proposal link
  async updateEstimate(uuid, data) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/estimates/' + uuid,
      data: data,
    });

    return res.data;
  },

  // [ ESTIMATES]
  // NOTE: UNSECURED!! Needed for estimate proposal link
  async approveOrDeclineEstimate(uuid, approved) {
    let res = await axios({
      method: 'PUT',
      url: `/service_jobs/estimates/approve_or_decline/${uuid}`,
      data: {status: approved ? 'Approved' : 'Declined'},
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  // [ ESTIMATE SECTIONS ]
  // NOTE: UNSECURED!! Needed for estimate proposal link
  async approveOrDeclineEstimateSection(uuid, approved) {
    let res = await axios({
      method: 'PUT',
      url: `/service_jobs/estimate_sections/approve_or_decline/${uuid}`,
      data: {approved: approved},
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async updateEstimateSection(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/estimate_sections/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async updateWorkOrder(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/work_orders/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async updateWorkTask(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/work_tasks/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async updateServiceRequest(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/service_requests/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async approveServiceRequest(uuid, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/service_requests/approve/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async approveEstimateProposal(uuid, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/estimates/approve/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async submitEstimateProposal(uuid, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/estimates/submit/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return estimate result
  },

  async declineEstimateProposal(uuid, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/estimates/decline/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return estimate result
  },

  async declineServiceRequest(uuid, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/service_requests/decline/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  //  [ JOBS ]
  // NOTE: UNSECURED! Needed for estimate proposal
  async updateJobPhase(uuid, phase) {
    let res = await axios({
      method: 'PUT',
      url: '/service_jobs/jobs/update_job_phase/' + uuid,
      data: {phase: phase},
    });
    return res.data;
  },

  // *****************************************************/
  //                      DELETE
  // *****************************************************/
  async deleteWorkOrder(uuid, accessToken) {
    let res = await axios({
      method: 'DELETE',
      url: '/service_jobs/work_orders/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async deleteWorkTask(uuid, accessToken) {
    let res = await axios({
      method: 'DELETE',
      url: '/service_jobs/work_tasks/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async deleteEstimateSection(uuid, accessToken) {
    let res = await axios({
      method: 'DELETE',
      url: '/service_jobs/estimate_sections/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  async createServiceRequest(data, accessToken) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_jobs/service_requests/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async createWorkTask(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_jobs/work_tasks/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async createWorkOrder(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_jobs/work_orders/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  //  [ ESTIMATES]
  // NOTE: UNSECURED! Needed for estimate proposal
  async createEstimateProposalPDF(data) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_jobs/estimates/estimate_proposal/pdf/',
      data: data,
    });
    return res.data;
  },

  //  [ ESTIMATE SECTIONS ] CREATE NEW
  async createEstimateSection(data, accessToken) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    var res = await axios({
      method: 'POST',
      url: '/service_jobs/estimate_sections/create',
      data: data,
      headers: {Authorization: `Bearer ${accessToken}`},
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  // *****************************************************/
  //                    GET BY PARAMS
  // *****************************************************/
  async getJobs(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/jobs/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getWorkTasks(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/work_tasks/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // sort result
    res.data.sort(function (a, b) {
      return a.id - b.id;
    });

    // return result
    return res.data;
  },

  // [ WORKTASKS ] NOTE: UNSECURED! Needed for estimate proposal
  async getWorkTasksByParams(params) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/work_tasks/link',
      params: params || {},
    });

    // return result
    return res.data;
  },

  async getEstimates(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/estimates/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getEstimateSections(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/estimate_sections/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ WORKTASKS ]
  // NOTE: UNSECURED! Needed for estimate proposal
  async getEstimateSectionsByEstimate(estimate_uuid) {
    let res = await axios({
      method: 'GET',
      url: `/service_jobs/estimate_sections/by_estimate/${estimate_uuid}`,
    });
    return res.data;
  },

  async getServiceRequests(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/service_requests/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getWorkOrders(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/work_orders/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // [ WORK ORDERS ]
  async getAllWorkOrders(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/work_orders/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res) {
      // console.log("work orders raw: ", res.data)
      for (var i = 0; i < res.data.length; i++) {
        // load work order events if there are any
        res.data[i].events = res.data[i].event_uuids
          ? await this.loadWorkOrderEventsByUUID(
              res.data[i].event_uuids,
              accessToken
            )
          : null;

        // load user (foreman/estimator) if one was assigned
        res.data[i].user = res.data[i].user_uuid
          ? await Users.getUser(res.data[i].user_uuid, accessToken)
          : null;
      }

      // console.log("work orders parsed: ", res.data);
      return res.data;
    }
  },

  async getJobPhasesByJobSite(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_jobs/jobs/getJobPhasesByJobSite',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getStatusByTrees(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_jobs/work_tasks/getStatusByTree',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getWorkOrderSnapshot(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_jobs/work_orders/export_snapshot/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getScheduledDateForWorkOrders(workorders, accessToken) {
    for (var i = 0; i < workorders.length; i++) {
      // this.workorders.forEach(async workorder => {
      workorders[i].events = await this.loadWorkOrderEventsByUUID(
        workorders[i].event_uuids,
        accessToken
      );
      if (workorders[i].events && workorders[i].events.length > 0) {
        workorders[i].scheduled_date = workorders[i].events[0].start_time;
      } else {
        workorders[i].scheduled_date = '';
      }
    }
    return workorders;
    // })
  },

  // [ W0RK ORDERS ] FIND BY TREE & OPEN STATUS
  async isTreePartOfOpenWorkOrders(uuid, accessToken) {
    var res = await axios({
      method: 'GET',
      url: '/service_jobs/work_orders/byTree',
      params: {tree_uuid: uuid},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data && res.data.length > 0) {
      return true;
    } else {
      return false;
    }
  },
};
